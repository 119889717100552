@font-face {
font-family: '__openSans_1009b1';
src: url(/_next/static/media/8f605fdd2ad38233-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__openSans_1009b1';
src: url(/_next/static/media/8f605fdd2ad38233-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__openSans_1009b1';
src: url(/_next/static/media/8f605fdd2ad38233-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__openSans_Fallback_1009b1';src: local("Arial");ascent-override: 101.58%;descent-override: 27.84%;line-gap-override: 0.00%;size-adjust: 105.22%
}.__className_1009b1 {font-family: '__openSans_1009b1', '__openSans_Fallback_1009b1'
}.__variable_1009b1 {--font-open-sans: '__openSans_1009b1', '__openSans_Fallback_1009b1'
}

